"use client";

import React from 'react';
import {
  APIProvider,
  Map,
} from "@vis.gl/react-google-maps";


export const MapPage = () => {
  const position = { lat: 40.587, lng: -74.147 };

  return (
    <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
    <div style={{height: "100vh"}}>
      <Map zoom={12} center={position} mapId="e8f68e821f46a5fc"></Map>
    </div>
    </APIProvider>
  );
};

export default Map;

import React, { useState, useEffect } from "react";
import { Navigation } from "../components/navigation";
import { Header } from "../components/header";
import { Features } from "../components/features";
import { About } from "../components/about";
import { Services } from "../components/services";
import { Gallery } from "../components/gallery";
import { Testimonials } from "../components/testimonials";
import { Team } from "../components/team";
import { Contact } from "../components/contact";
import JsonData from "../data/data.json";

export const LandingPage = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
  setLandingPageData(JsonData);
  }, []);

  return (
      <div id="landing_page">
        <Navigation />
        <Header data={landingPageData.Header} />
        <Features data={landingPageData.Features} />
        <About data={landingPageData.About} />
        {/* <Services data={landingPageData.Services} /> */}
        <Gallery data={landingPageData.Gallery} />
        <Testimonials data={landingPageData.Testimonials} />
        <Team data={landingPageData.Team} />
        <Contact data={landingPageData.Contact} />
    </div>  
  );
};

export default LandingPage;

import React from "react";
import { Routes, Route } from "react-router-dom";
import { LandingPage } from "./pages/landing_page";
import { MapPage } from "./pages/map";
import SmoothScroll from "smooth-scroll";
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {


  return (
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/map" element={<MapPage />} />
      </Routes>
  )
}

export default App;
